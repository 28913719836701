@import "./variables";
@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Glory:wght@300;400;500;700&display=swap');


@import "./home";
@import "./footer";
@import "./navbar";
@import "./media_queries";
@import "./videos";
@import "./music";
@import "./contact";
@import "./mailing_list";
@import "./bio";
@import "./photos";
@import "./merch";

body {
    margin: 0;
    padding: 0;
    background-image: linear-gradient(90deg, rgb(22, 22, 22), rgb(46, 46, 46));
    min-height: 100vh;
    max-width: 100vw;
    background-size: cover;
    background-attachment: fixed;
}

.app {
    position: relative;
    min-height: 100vh;
}

html {
    font-family: 'Glory', sans-serif;
}

h1, h2, h3, h4, h5 {
    font-family: 'Julius Sans One', sans-serif;
}