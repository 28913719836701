@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Glory:wght@300;400;500;700&display=swap");
.hero-container {
  width: 100vw;
  height: 70vh;
}

.hero-image {
  background-image: url("../images/cover.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.middle-container {
  width: 100vw;
  height: 80vh;
  margin: -3rem 0;
}

.middle-image {
  background-image: url("../images/show1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}

.parallax-text {
  font-size: 3rem;
  color: #e7e7e7;
}

.main-container,
.member-parent-container {
  background-image: -webkit-gradient(linear, left top, right top, from(#161616), to(#2e2e2e));
  background-image: linear-gradient(90deg, #161616, #2e2e2e);
  padding: 2rem 0;
  color: #26c1e0;
  text-align: center;
  position: relative;
  z-index: 10;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
}

.featured-container {
  margin-bottom: 4rem;
}

.iframe-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.show-title {
  text-align: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.show-title h4 {
  color: #999999;
  margin-bottom: 0;
  letter-spacing: .2em;
}

.show-title h2 {
  margin: 0;
  color: #e7e7e7;
}

.show-title h5 {
  margin-top: 0;
  color: #999999;
}

.show-title .address-container,
.show-title .site-link-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -ms-flex-item-align: start;
      align-self: start;
  margin-top: .5rem;
  color: #999999;
  color: #26c1e0;
}

.show-title .address-container:hover,
.show-title .site-link-container:hover {
  color: #5766ba;
  text-decoration: underline;
}

.members-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.band-member {
  margin: .5rem;
  width: 15rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.band-member .member-image-container {
  width: 15rem;
  height: 15rem;
}

.band-member .member-image {
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  max-height: 100%;
  border-radius: 5px;
}

.band-member .member-name {
  margin-bottom: 0;
  padding-left: .5rem;
  text-align: start;
  color: #1f1f1f;
}

.band-member .accordian-content {
  color: #1f1f1f;
  background-color: white;
  margin: 1rem;
  margin-top: 0;
}

.band-member .click-for-more {
  font-size: .8em;
  position: absolute;
  bottom: 0;
  right: 1rem;
}

.featured-content {
  position: absolute;
}

.featured-audio-header {
  color: #1f1f1f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.featured-audio-header h3 {
  margin-bottom: 0;
  margin-right: 2rem;
  letter-spacing: .2em;
}

.featured-audio-header a {
  color: #1f1f1f;
}

.featured-audio-header a:hover {
  color: #26c1e0;
}

.featured-audio {
  width: 100%;
  margin-top: 1rem;
}

.featured-poster {
  max-height: 75vh;
  max-width: 80vw;
}

.featured-poster {
  max-height: 75vh;
  max-width: 90vw;
  margin-top: 1rem;
}

.footer-container {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background: #e7e7e7;
  margin-top: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.footer-container p {
  margin: .5em 0 0;
}

.footer-list {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding-left: 0;
}

.footer-list li a {
  color: #1f1f1f;
  margin-right: .5rem;
}

.footer-list li a:hover {
  color: #26c1e0;
  cursor: pointer;
}

.navbar {
  background-image: -webkit-gradient(linear, left top, right top, from(#161616), to(#2e2e2e));
  background-image: linear-gradient(90deg, #161616, #2e2e2e);
  background-color: #e7e7e7;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: .5em;
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.navbar .navbar-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  width: 70%;
  margin-left: auto;
  list-style: none;
}

.navbar .bars-icon {
  display: none;
  margin-right: .5rem;
}

.brand-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-left: 2rem;
}

.brand-container h5 {
  margin: 0;
  color: #26c1e0;
}

.nav-link {
  color: #e7e7e7;
  padding: 1rem 1.5rem;
  margin: 0;
}

.nav-link:hover {
  background: #3e3d3f;
  color: #e7e7e7;
}

.active {
  color: #e7e7e7;
  background: #3e3d3f;
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.sidebar-link {
  color: #1f1f1f;
  height: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  letter-spacing: .2em;
  font-weight: bold;
  font-family: 'Julius Sans One', sans-serif;
}

.sidebar-link:hover {
  color: #1f1f1f;
}

.nav-dropdown {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  position: fixed;
  top: 3.8rem;
  background-image: -webkit-gradient(linear, left top, right top, from(#222222), to(#252525));
  background-image: linear-gradient(90deg, #222222, #252525);
  padding: 0;
}

.nav-dropdown .active {
  background: unset;
  border-bottom: 3px solid #e7e7e7;
  padding-bottom: .5rem;
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.nav-social {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding-left: 0;
}

.nav-social li a {
  color: #e7e7e7;
  margin-right: .5rem;
}

.nav-social li a:hover {
  color: #26c1e0;
}

@media (min-width: 600px) and (max-width: 800px) {
  .navbar-links {
    width: 100%;
  }
  .nav-link {
    font-size: .7em;
    margin: 0;
    padding: 1rem;
  }
}

@media (min-width: 200px) and (max-width: 600px) {
  .navbar .navbar-links {
    display: none;
  }
  .navbar .brand-container {
    margin: 0 auto;
  }
  .navbar .bars-icon {
    display: block;
  }
  .navbar .empty-div {
    width: 2.5rem;
  }
  .hero-image {
    height: 70%;
    margin-top: 1rem;
    background-position: top;
  }
  .hero-container {
    height: 50vh;
    margin-bottom: -7rem;
  }
  .middle-container {
    height: 70vh;
    margin: -4rem 0;
  }
  .middle-image {
    background-position: center;
  }
  .scroll-box {
    max-height: 22vh;
    overflow-y: scroll;
    background: #f3f3f3;
    padding: .5em;
    margin: .5em;
    border-radius: 10px 0 0 10px;
  }
}

.videos-page-container h1 {
  color: #26c1e0;
  text-align: center;
}

.videos-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 10rem;
}

.videos-container iframe {
  margin: .5rem;
  min-width: 100%;
  height: 100%;
}

.videos-container .video-placeholder {
  padding: 0 -2rem;
  margin: 1rem;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 460px;
  height: 250px;
}

.songs-page-container {
  margin-top: 7rem;
  padding-bottom: 8rem;
}

.songs-page-container h1 {
  color: #26c1e0;
  text-align: center;
}

.songs-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.music-content {
  position: absolute;
  bottom: 0;
}

.music-audio-header {
  color: #1f1f1f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.music-audio-header h3 {
  margin-bottom: 0;
  margin-right: 2rem;
  letter-spacing: .2em;
}

.music-audio-header a {
  color: #1f1f1f;
}

.music-audio-header a:hover {
  color: #26c1e0;
}

.music-audio {
  width: 100%;
  margin-top: 1rem;
}

.form {
  width: 40rem;
  margin: 0 auto;
  background-image: linear-gradient(135deg, #5766ba, #26c1e0);
  padding: 2rem;
  border-radius: 12px;
}

.contact-parallax {
  height: 60vh;
  width: 100vw;
  margin-top: -3rem;
}

.contact-parallax-image {
  background-image: url("../images/show2.jpg");
  height: 100%;
  width: 100vw;
  background-position: center;
  background-size: cover;
}

.contact-container {
  position: relative;
  z-index: 10;
  background-image: -webkit-gradient(linear, left top, right top, from(#161616), to(#2e2e2e));
  background-image: linear-gradient(90deg, #161616, #2e2e2e);
  -webkit-box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.5);
          box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.5);
}

.contact-title-container,
.book-container {
  text-align: center;
  color: #26c1e0;
}

.book-container {
  margin-bottom: 3rem;
}

.social-list {
  list-style: none;
  margin-bottom: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-left: 0;
  margin-bottom: 0;
}

.contact-item-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  color: #e7e7e7;
  margin: 1rem 0;
}

.loader-container {
  position: absolute;
  bottom: 10%;
  right: 50%;
}

.loader-icon {
  background-color: transparent;
  color: #1f1f1f;
}

.close-x {
  position: absolute;
  top: .5rem;
  right: .5rem;
  color: #505050;
}

.portal-container {
  text-align: center;
  width: 20rem;
  margin: 2rem;
}

.portal-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.mailing-input {
  margin: 1rem;
}

.portal-p {
  font-size: .8em;
}

.mailing-title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.mailing-title-container img {
  margin-top: 1rem;
}

.bio-container {
  color: #1f1f1f;
}

.bio-list {
  list-style: none;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  padding-left: 0;
}

.bio-list li::before {
  content: '';
  display: inline-block;
  background-image: url("../images/logo2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 15px;
  width: 15px;
}

.photo-page-container h1 {
  color: #26c1e0;
  text-align: center;
}

.photos-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 4rem;
}

.button-group-container {
  text-align: center;
}

.photo-image-container {
  height: 15rem;
  width: 20rem;
  margin: .5rem;
}

.photo-image-container:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.photo-image {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}

.modal-image {
  max-height: 80vh;
  margin: 0 auto;
}

.merch-page-container {
  margin-top: 7rem;
}

.merch-page-container h1 {
  color: #26c1e0;
  text-align: center;
}

.merch-page-container h3, .merch-page-container h5 {
  color: #e7e7e7;
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#161616), to(#2e2e2e));
  background-image: linear-gradient(90deg, #161616, #2e2e2e);
  min-height: 100vh;
  max-width: 100vw;
  background-size: cover;
  background-attachment: fixed;
}

.app {
  position: relative;
  min-height: 100vh;
}

html {
  font-family: 'Glory', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'Julius Sans One', sans-serif;
}
