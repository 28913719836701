.videos-page-container {
    h1 {
        color: $highlight-color;
        text-align: center;
    }
}

.videos-container {
    @include flexCenter(row);
    flex-wrap: wrap;
    padding-bottom: 10rem;

    iframe {
        margin: .5rem;
        min-width: 100%;
        height: 100%;
    }

    .video-placeholder {
        padding: 0 -2rem;
        margin: 1rem;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 460px;
        height: 250px;
    }
}