@media (min-width: 600px) and (max-width: 800px) {

    .navbar-links {
        width: 100%;
    }
    .nav-link {
        font-size: .7em;
        margin: 0;
        padding: 1rem;
    }
}

@media (min-width: 200px) and (max-width: 600px) {

    .navbar {

        .navbar-links {
            display: none;
        }
    
        .brand-container{
            margin: 0 auto;
        }
    
        .bars-icon {
            display: block;
        }

        .empty-div {
            width: 2.5rem;
        }
    }

    .hero-image {
        height: 70%;
        margin-top: 1rem;
        background-position: top;
    }

    .hero-container {
        height: 50vh;
        margin-bottom: -7rem;
    }

    .middle-container {
        height: 70vh;
        margin: -4rem 0;
    }

    .middle-image {
        background-position: center;
    }

    .scroll-box {
        max-height: 22vh;
        overflow-y: scroll;
        background: rgb(243, 243, 243);
        padding: .5em;
        margin: .5em;
        border-radius: 10px 0 0 10px;
    }
}

