.songs-page-container {

    margin-top: 7rem; 
    padding-bottom: 8rem;

    h1 {
        color: $highlight-color;
        text-align: center;
    }
}

.songs-container {
    @include flexCenter(row);
    flex-wrap: wrap;
}

.music-content {
    position: absolute;
    bottom: 0;
}

.music-audio-header {
    color: $dark-color;
    @include flexCenter(row);

    h3 {
        margin-bottom: 0;
        margin-right: 2rem;
        letter-spacing: .2em;
    }

    a {
        color: $dark-color;

        &:hover {
            color: $highlight-color;
        }
    }
}

.music-audio {
    width: 100%;
    margin-top: 1rem;
}