.merch-page-container {
    margin-top: 7rem;

    h1 {
        color: $highlight-color;
        text-align: center;
    }

    h3, h5 {
        color: $light-color;
        text-align: center;
    }
}