.form {
    width: 40rem;
    margin: 0 auto;
    background-image: linear-gradient(135deg, $primary-color, $highlight-color);
    padding: 2rem;
    border-radius: 12px;
}

.contact-parallax {
    height: 60vh;
    width: 100vw;
    margin-top: -3rem;
}

.contact-parallax-image {
    background-image: url('../images/show2.jpg');
    height: 100%;
    width: 100vw;
    background-position: center;
    background-size: cover;
}

.contact-container {
    position: relative;
    z-index: 10;
    background-image: linear-gradient(90deg, rgb(22, 22, 22), rgb(46, 46, 46));
    box-shadow: -5px -5px 5px rgba(0,0,0,.5);
}

.contact-title-container,
.book-container {
    text-align: center;
    color: $highlight-color;
}

.book-container {
    margin-bottom: 3rem;
}

.social-list {
    list-style: none;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    margin-bottom: 0;
}

.contact-item-container {
    @include flexCenter(row);
    color: $light-color;
    margin: 1rem 0;
}

.loader-container {
    position: absolute;
    bottom: 10%;
    right: 50%;
}

.loader-icon{
    background-color: transparent;
    color: $dark-color;
}
