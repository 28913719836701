.close-x {
    position: absolute;
    top: .5rem;
    right: .5rem;
    color: rgb(80, 80, 80);
}

.portal-container {
    text-align: center;
    width: 20rem;
    margin: 2rem;
}

.portal-buttons {
    @include flexCenter(row);
    justify-content: space-evenly;
}

.mailing-input {
    margin: 1rem;
}

.portal-p {
    font-size: .8em;
}

.mailing-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    img {
        margin-top: 1rem;
    }
}
