.photo-page-container {
    h1 {
        color: $highlight-color;
        text-align: center;
    }
}

.photos-container {
    @include flexCenter(row);
    flex-wrap: wrap;
    margin-top: 4rem;

}

.button-group-container {
    text-align: center;
}

.photo-image-container {
    height: 15rem;
    width: 20rem;
    margin: .5rem;

    &:hover {
        transform: scale(1.05);
        box-shadow: 5px 5px 5px rgba(0,0,0,.3);
        cursor: pointer;
    }
}

.photo-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.modal-image {
    max-height: 80vh;
    margin: 0 auto;
}