$light-color: rgb(231, 231, 231);
$dark-color: rgb(31, 31, 31);
$highlight-color: #26c1e0;
$primary-color: #5766ba;


@mixin flexCenter($direction) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: $direction;
}


@mixin testBorder {
    border: 1px solid red;
}