.navbar {  
    background-image: linear-gradient(90deg, rgb(22, 22, 22), rgb(46, 46, 46));
    background-color: $light-color;
    width: 100vw;
    @include flexCenter(row);
    padding: .5em;
    box-shadow: 5px 5px 5px rgba(0,0,0,.2);

    .navbar-links {
        @include flexCenter(row);
        justify-content: space-evenly;
        width: 70%;
        margin-left: auto;
        list-style: none;
    }

    .bars-icon {
        display: none;
        margin-right: .5rem;
    }
}

.brand-container {
    @include flexCenter(row);
    margin-left: 2rem;

    h5{
        margin: 0;
        color: $highlight-color;
    }
}
.nav-link {
    color: $light-color;
    padding: 1rem 1.5rem;
    margin: 0;
    &:hover {
        background: #3e3d3f;
        color: $light-color;
    }
}

.active {
    color: $light-color;
    background: #3e3d3f;
    box-shadow: 5px 5px 5px rgba(0,0,0,.1);
}

.sidebar-link {
    color: $dark-color;
    height: 4rem;
    @include flexCenter(row);
    letter-spacing: .2em;
    font-weight: bold;
    font-family: 'Julius Sans One', sans-serif;

    &:hover {
        color: $dark-color;
    }
}

.nav-dropdown {
    list-style: none;
    @include flexCenter(column);
    text-align: center;
    position: fixed;
    top: 3.8rem;
    background-image: linear-gradient(90deg, rgb(34, 34, 34), rgb(37, 37, 37));
    padding: 0;

    .active {
        background: unset;
        border-bottom: 3px solid $light-color;
        padding-bottom: .5rem;
        box-shadow: unset;
    }
}

.hidden {
    display: none;
}

.visible {
    display: block;
}

.nav-social {
    list-style: none;
    @include flexCenter(row);
    padding-left: 0;

    li a{
        color: $light-color;
        margin-right: .5rem;
        &:hover {
            color: $highlight-color;
        }
    }
}