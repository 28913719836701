.footer-container {
    position: absolute;
    bottom: 0;
    z-index: 10;
    width: 100%;
    background: $light-color;
    margin-top: 5rem;
    @include flexCenter(column);

    p {
        margin: .5em 0 0;
    }
}

.footer-list {
    list-style: none;
    @include flexCenter(row);
    padding-left: 0;

    li a{
        color: $dark-color;
        margin-right: .5rem;
        
        &:hover {
            color: $highlight-color;
            cursor: pointer;
        }
    }
}