.hero-container {
    width: 100vw;
    height: 70vh;
}

.hero-image {
    background-image: url("../images/cover.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

.middle-container {
    width: 100vw;
    height: 80vh;
    margin: -3rem 0;
}

.middle-image {
    background-image: url("../images/show1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
}

.parallax-text {
    font-size: 3rem;
    color: $light-color;
}

.main-container,
.member-parent-container {
    background-image: linear-gradient(90deg, rgb(22, 22, 22), rgb(46, 46, 46));
    padding: 2rem 0;
    color: $highlight-color;
    text-align: center;
    position: relative;
    z-index: 10;
    box-shadow: 0px 0px 10px rgba(0,0,0,.9);
}

.featured-container {
    margin-bottom: 4rem;
}

.iframe-container {
    display: flex;
    justify-content: center;
}

.show-title{
    text-align: start;
    display: flex;
    flex-direction: column;

    h4 {
        color: rgb(153, 153, 153);
        margin-bottom: 0;
        letter-spacing: .2em;
    }

    h2 {
        margin: 0;
        color: $light-color;
    }

    h5 {
        margin-top: 0;
        color: rgb(153, 153, 153);
    }
    .address-container,
    .site-link-container {
        @include flexCenter(row);
        align-items: start;
        align-self: start;
        margin-top: .5rem;
        color: rgb(153, 153, 153);
        color: $highlight-color;

        &:hover {
            color: $primary-color;
            text-decoration: underline;
        }
    }
}

.members-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.band-member {
    margin: .5rem;
    width: 15rem;
    @include flexCenter(column);

    .member-image-container {
        width: 15rem;
        height: 15rem;
    }

    .member-image {
        box-shadow: 5px 5px 5px rgba(0,0,0,.3);
        object-fit: cover;
        width: 100%;
        max-height:  100%;
        border-radius: 5px;
    }

    .member-name {
        margin-bottom: 0;
        padding-left: .5rem;
        text-align: start;
        color: $dark-color;
    }

    .accordian-content {
        color: $dark-color;
        background-color: white;
        margin: 1rem;
        margin-top: 0;
    }

    .click-for-more {
        font-size: .8em;
        position: absolute;
        bottom: 0;
        right: 1rem;
    }
}

.featured-content {
    position: absolute;
}

.featured-audio-header {
    color: $dark-color;
    @include flexCenter(row);

    h3 {
        margin-bottom: 0;
        margin-right: 2rem;
        letter-spacing: .2em;
    }

    a {
        color: $dark-color;

        &:hover {
            color: $highlight-color;
        }
    }
}

.featured-audio {
    width: 100%;
    margin-top: 1rem;
}

.featured-poster {
    max-height: 75vh;
    max-width: 80vw;
}

.featured-poster {
    max-height: 75vh;
    max-width: 90vw;
    margin-top: 1rem;
}