.bio-container {
    color: $dark-color;
}

.bio-list {
    list-style: none;
    margin: 0;
    @include flexCenter(column);
    align-items: start;
    padding-left: 0;
    li{
        &::before {
            content: '';
            display: inline-block;
            background-image: url("../images/logo2.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: 15px;
            width: 15px;
        }
    }
}
